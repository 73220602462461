import React from 'react';
import { KeyValue, Panel } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import { formatDate, toDate } from '@sonika-se/ui-kit/utils/time';
export const UserInfoPanel = (props) => {
    var _a;
    const { userRef } = props;
    const user = useFragment(graphql `
      fragment UserInfoPanel_user on User {
        timestampId
        username
        company {
          name
        }
      }
    `, userRef);
    return (<>
      <Panel title="Allmänt">
        <KeyValue label="Skapad" value={formatDate(toDate(user.timestampId), 'dateTime')}/>
        <KeyValue label="Företag" value={user.company.name}/>
      </Panel>
      <Panel title="Inloggning">
        <KeyValue label="Användarnamn" value={(_a = user.username) !== null && _a !== void 0 ? _a : 'Inget användarnamn'}/>
      </Panel>
    </>);
};
export default UserInfoPanel;
