import React, { useImperativeHandle, useMemo } from 'react';
import { graphql, useRefetchableFragment } from 'react-relay/hooks';
export const StatDetails = React.forwardRef((props, forwardedRef) => {
    const { queryRef, onRefreshComplete } = props;
    console.log('render stats');
    const [data, refetch] = useRefetchableFragment(graphql `
      fragment StatDetails_query on Company @refetchable(queryName: "StatDetailsQuery") {
        activeServices
        stats {
          userStats {
            usersPerService {
              service
              numberOfUsers
            }
            numberOfActiveUsers
            numberOfActiveInvitedUsers
            numberOfCompanyAdmins
          }
          assetStats {
            numberOfActiveAssets
          }
          timeStats {
            numberOfTimeReportingUsers
          }
          scheduleStats {
            numberOfSchedulableAssets
            numberOfSchedulableUsers
            numberOfSchedules
          }
          employmentStats {
            numberOfActiveEmployments
          }
          surveyStats {
            numberOfSurveys
            numberOfActiveSurveys
            numberOfCollectors
            numberOfActiveCollectors
            numberOfResponses
            numberOfFinishedResponses
            numberOfActiveResponses
            numberOfActiveFinishedResponses
          }
        }
      }
    `, queryRef);
    useImperativeHandle(forwardedRef, () => ({
        refresh: () => {
            console.log('refresh');
            refetch({}, {
                fetchPolicy: 'network-only',
                onComplete: () => onRefreshComplete === null || onRefreshComplete === void 0 ? void 0 : onRefreshComplete(),
            });
        },
    }), [onRefreshComplete, refetch]);
    const jsonData = useMemo(() => {
        console.log('stringify');
        return JSON.stringify(data, null, 2);
    }, [data]);
    return (<div>
      <pre>{jsonData}</pre>
    </div>);
});
export default StatDetails;
