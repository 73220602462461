import React, { useMemo } from 'react';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { DataTable } from '@sonika-se/ui-kit/components';
import { formatDate, toDate } from '@sonika-se/ui-kit/utils/time';
export const AssignmentTypeTable = (props) => {
    const { queryRef } = props;
    const { data, hasNext, loadNext } = usePaginationFragment(graphql `
      fragment AssignmentTypeTable_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        first: { type: "Int", defaultValue: 50 }
        where: { type: "AssignmentTypeFilterInput" }
      )
      @refetchable(queryName: "AssignmentTypeTablePaginationQuery") {
        admin_assignmentTypes(after: $after, first: $first, order: { timestampId: DESC }, where: $where)
          @connection(key: "AssignmentTypeTable_query_admin_assignmentTypes", filters: ["where"]) {
          edges {
            node {
              id
              timestampId
              title
            }
          }
        }
      }
    `, queryRef);
    const assignmentTypes = useMemo(() => data.admin_assignmentTypes.edges.map(({ node: { timestampId, title } }) => ({
        timestampId,
        title,
    })), [data]);
    return (<DataTable columns={{
            title: {
                text: 'Titel',
            },
            timestampId: {
                render: (timestamp) => formatDate(toDate(timestamp), 'dateTime'),
                text: 'Skapad',
            },
        }} onPaginate={() => loadNext(50)} pagination={{
            buttonText: 'Ladda fler',
            hasNextPage: hasNext,
        }} rows={assignmentTypes}/>);
};
export default AssignmentTypeTable;
