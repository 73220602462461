/**
 * @generated SignedSource<<1517d73a491c009a5d3a55a4ca60b035>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserLink_user"
        }
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "newValues",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "objectId",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "objectType",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "oldValues",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tableName",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timestamp",
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "authenticationDescription",
        "storageKey": null
    }, v11 = [
        (v2 /*: any*/),
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AuditModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Company",
                                    "kind": "LinkedField",
                                    "name": "company",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "args": null,
                                            "kind": "FragmentSpread",
                                            "name": "CompanyLink_company"
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "impersonatingUser",
                                    "plural": false,
                                    "selections": (v3 /*: any*/),
                                    "storageKey": null
                                },
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": (v3 /*: any*/),
                                    "storageKey": null
                                },
                                (v10 /*: any*/)
                            ],
                            "type": "Audit",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AuditModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Company",
                                    "kind": "LinkedField",
                                    "name": "company",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "impersonatingUser",
                                    "plural": false,
                                    "selections": (v11 /*: any*/),
                                    "storageKey": null
                                },
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": (v11 /*: any*/),
                                    "storageKey": null
                                },
                                (v10 /*: any*/)
                            ],
                            "type": "Audit",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "948b6df6a9fb9de2e3cb05f8b7338612",
            "id": null,
            "metadata": {},
            "name": "AuditModalQuery",
            "operationKind": "query",
            "text": "query AuditModalQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Audit {\n      company {\n        ...CompanyLink_company\n        id\n      }\n      id\n      impersonatingUser {\n        ...UserLink_user\n        id\n      }\n      newValues\n      objectId\n      objectType\n      oldValues\n      tableName\n      timestamp\n      user {\n        ...UserLink_user\n        id\n      }\n      authenticationDescription\n    }\n    id\n  }\n}\n\nfragment CompanyLink_company on Company {\n  id\n  name\n}\n\nfragment UserLink_user on User {\n  id\n  firstname\n  lastname\n}\n"
        }
    };
})();
node.hash = "61910f2077e868554b8025bc02a3a180";
export default node;
