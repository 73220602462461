/**
 * @generated SignedSource<<fb695245f31562672326b6751f250f1a>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSidebar_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
};
node.hash = "9161233e635e91db6dc05dd5ddd7b6ff";
export default node;
