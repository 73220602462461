/**
 * @generated SignedSource<<46887ed5dd34d2acb50d1662225ef7d6>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanyTablePanel_query",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompanyTable_query"
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "d80f6ca6fb229c961fa18e8248477e83";
export default node;
