import React from 'react';
import { useLazyLoadQuery, graphql, useRefetchableFragment } from 'react-relay/hooks';
const SystemTexts = (props) => {
    const { onChange } = props;
    const data = useLazyLoadQuery(graphql `
      query SystemTextsGetTextsQuery($locale: Locale!) {
        ...SystemTexts_texts @arguments(locale: $locale)
      }
    `, {
        locale: 'SV_SE',
    });
    const [texts] = useRefetchableFragment(graphql `
      fragment SystemTexts_texts on Query
      @argumentDefinitions(locale: { type: "Locale!" })
      @refetchable(queryName: "SystemTexts_textsQuery") {
        texts(locale: $locale) {
          edges {
            node {
              key
              value
              locale
              scope
              id
            }
          }
        }
      }
    `, data);
    React.useEffect(() => {
        var _a;
        onChange((_a = texts === null || texts === void 0 ? void 0 : texts.texts) === null || _a === void 0 ? void 0 : _a.edges);
    }, [texts]);
    return <div />;
};
export default SystemTexts;
