/**
 * @generated SignedSource<<24572caddc75967dd98a690a53ddadc6>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "locale"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "locale",
            "variableName": "locale"
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SystemTextsGetTextsQuery",
            "selections": [
                {
                    "args": (v1 /*: any*/),
                    "kind": "FragmentSpread",
                    "name": "SystemTexts_texts"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SystemTextsGetTextsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "TextConnection",
                    "kind": "LinkedField",
                    "name": "texts",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TextEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Text",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "key",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "value",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "locale",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "scope",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "8f44af187e1dddadf0e4e4595302a8d7",
            "id": null,
            "metadata": {},
            "name": "SystemTextsGetTextsQuery",
            "operationKind": "query",
            "text": "query SystemTextsGetTextsQuery(\n  $locale: Locale!\n) {\n  ...SystemTexts_texts_4mYyp4\n}\n\nfragment SystemTexts_texts_4mYyp4 on Query {\n  texts(locale: $locale) {\n    edges {\n      node {\n        key\n        value\n        locale\n        scope\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "13966fa1cc9b72a4387616b270616501";
export default node;
