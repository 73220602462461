import React from 'react';
import { Panel } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import CompanyTable from '~/tables/CompanyTable';
export const CompanyTablePanel = (props) => {
    const { queryRef } = props;
    const data = useFragment(graphql `
      fragment CompanyTablePanel_query on Query {
        ...CompanyTable_query
      }
    `, queryRef);
    return (<Panel title="Företag">
      <CompanyTable queryRef={data}/>
    </Panel>);
};
export default CompanyTablePanel;
