import React, { useMemo } from 'react';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { DataTable } from '@sonika-se/ui-kit/components';
import { formatDate, toDate } from '@sonika-se/ui-kit/utils/time';
import { Link } from 'react-router-dom';
import enums from '~/enums.json';
const { Service } = enums;
export const CompanyTable = (props) => {
    const { queryRef } = props;
    const { data, hasNext, loadNext } = usePaginationFragment(graphql `
      fragment CompanyTable_query on Query
      @argumentDefinitions(after: { type: "String" }, first: { type: "Int", defaultValue: 50 })
      @refetchable(queryName: "CompanyTablePaginationQuery") {
        admin_companies(after: $after, first: $first, order: { timestampId: DESC })
          @connection(key: "CompanyTable_query_admin_companies", filters: ["where"]) {
          edges {
            node {
              activeServices
              administrationEmail
              id
              name
              timestampId
              users {
                totalCount
              }
            }
          }
        }
      }
    `, queryRef);
    const companies = useMemo(() => data.admin_companies.edges.map(({ node: { activeServices, administrationEmail, id, name, timestampId, users } }) => ({
        activeServices,
        administrationEmail,
        name: {
            id,
            name,
        },
        timestampId,
        userCount: users.totalCount,
    })), [data]);
    return (<DataTable columns={{
            name: {
                render: ({ id, name }) => <Link to={`/companies/${id}`}>{name}</Link>,
                text: 'Namn',
            },
            activeServices: {
                text: 'Tjänster',
                render: (services) => services.map((service) => { var _a; return (_a = Service[service]) === null || _a === void 0 ? void 0 : _a.description; }).join(', '),
            },
            administrationEmail: {
                render: (email) => email && <a href={`mailto:${email}`}>{email}</a>,
                text: 'E-post admin',
            },
            userCount: {
                text: 'Antal användare',
            },
            timestampId: {
                render: (timestamp) => formatDate(toDate(timestamp), 'dateTime'),
                text: 'Skapat',
            },
        }} onPaginate={() => loadNext(50)} pagination={{
            buttonText: 'Ladda fler',
            hasNextPage: hasNext,
        }} rows={companies}/>);
};
export default CompanyTable;
