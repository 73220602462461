import React, { useState, Suspense, useMemo } from 'react';
import { graphql } from 'react-relay';
import { useLazyLoadQuery, useQueryLoader } from 'react-relay/hooks';
import { Flexbox, ModalToSheetSkeleton, IntervalInput, Skeleton, BasicPage, BasicPageHeader, Label, Select, Panel, Card, CardContent, Input, } from '@sonika-se/ui-kit/components';
import { AuditTable } from '../tables/AuditTable';
import AuditModal, { auditModalQuery } from '../modals/AuditModal';
import { useDebounce } from '@sonika-se/ui-kit/hooks';
export const AuditIndexPage = () => {
    const [auditModalQueryRef, loadAuditModalQuery, disposeAuditModalQuery] = useQueryLoader(auditModalQuery);
    const data = useLazyLoadQuery(graphql `
      query AuditIndexPageQuery {
        ...AuditTable_query
        admin_companies(first: 100, order: { name: ASC }) {
          nodes {
            id
            name
          }
        }
      }
    `, {});
    const companies = useMemo(() => data.admin_companies.nodes, [data.admin_companies]);
    const [dateInterval, setDateInterval] = useState({
        toDate: undefined,
        fromDate: undefined,
    });
    const [filters, setFilters] = useState({
        type: '',
        companyId: '',
    });
    const delayedFilters = useDebounce(filters, 300);
    return (<>
      <BasicPage width="large">
        <BasicPageHeader title="Aktivitetslogg"/>
        <Panel>
          <Flexbox direction="column" gap="small">
            <Card background="gray" shadow="none">
              <CardContent>
                <Flexbox gap="medium">
                  <Label lineHeight="double" text="Datum">
                    <IntervalInput value={dateInterval} placeholder="Välj datum" onChange={(value) => {
            setDateInterval(value);
        }}/>
                  </Label>
                  <Label lineHeight="double" text="Objektyp">
                    <Input type="text" inputName="type" onChange={(value) => {
            setFilters(Object.assign(Object.assign({}, filters), { type: value }));
        }}/>
                  </Label>
                  <Label lineHeight="double" text="Företag">
                    <Select inputName="company" selectedItems={companies === null || companies === void 0 ? void 0 : companies.filter(({ id }) => id === (filters === null || filters === void 0 ? void 0 : filters.companyId)).map((x) => ({ label: x.name, value: x.id }))} placeholder="Välj företag" onChange={(value) => {
            var _a;
            setFilters(Object.assign(Object.assign({}, filters), { companyId: (value === null || value === void 0 ? void 0 : value.length) > 0 ? (_a = value[0]) === null || _a === void 0 ? void 0 : _a.value : '' }));
        }} options={companies.map((x) => ({ label: x.name, value: x.id }))}/>
                  </Label>
                </Flexbox>
              </CardContent>
            </Card>
            <div>
              <Suspense fallback={<Skeleton height="40vh" type="table"/>}>
                <AuditTable filters={delayedFilters} dateInterval={dateInterval} onShowObject={(id) => {
            loadAuditModalQuery({
                id,
            });
        }} queryRef={data}/>
              </Suspense>
            </div>
          </Flexbox>
        </Panel>
      </BasicPage>
      <Suspense fallback={<ModalToSheetSkeleton />}>
        {auditModalQueryRef && (<AuditModal onClose={() => disposeAuditModalQuery()} preloadedQuery={auditModalQueryRef}/>)}
      </Suspense>
    </>);
};
