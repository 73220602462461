import React, { useMemo } from 'react';
import { Flexbox } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { useParams } from 'react-router';
import CompanySidebar from '~/sidebars/CompanySidebar';
import CompanyInfoPanel from '~/panels/CompanyInfoPanel';
export const CompanyDetailsPage = () => {
    const { companyId } = useParams();
    const data = useLazyLoadQuery(graphql `
      query CompanyDetailsPageQuery($companyId: ID!) {
        admin_company(id: $companyId) {
          ...CompanyInfoPanel_company
          ...CompanySidebar_company
        }
      }
    `, {
        companyId,
    });
    const company = useMemo(() => data.admin_company, [data]);
    return (<Flexbox direction="row" gap="large">
      <div style={{ flex: 3 }}>
        <CompanyInfoPanel companyRef={company}/>
      </div>
      <div style={{ flex: 1 }}>
        <CompanySidebar companyRef={company}/>
      </div>
    </Flexbox>);
};
export default CompanyDetailsPage;
