/**
 * @generated SignedSource<<7d6882f6420c66ff1f43f29d77542ae9>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "userId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserDetailsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "admin_user",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserInfoPanel_user"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserSidebar_user"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserDetailsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "admin_user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "timestampId",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "1b66b32390c8c50f32797de47381d9b1",
            "id": null,
            "metadata": {},
            "name": "UserDetailsPageQuery",
            "operationKind": "query",
            "text": "query UserDetailsPageQuery(\n  $userId: ID!\n) {\n  admin_user(id: $userId) {\n    ...UserInfoPanel_user\n    ...UserSidebar_user\n    id\n  }\n}\n\nfragment UserInfoPanel_user on User {\n  timestampId\n  username\n  company {\n    name\n    id\n  }\n}\n\nfragment UserSidebar_user on User {\n  id\n}\n"
        }
    };
})();
node.hash = "dd34f229252efcc19e04368775871ad0";
export default node;
