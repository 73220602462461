import React from 'react';
import { KeyValue, Panel } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import { formatDate, toDate } from '@sonika-se/ui-kit/utils/time';
export const CompanyInfoPanel = (props) => {
    const { companyRef } = props;
    const company = useFragment(graphql `
      fragment CompanyInfoPanel_company on Company {
        timestampId
      }
    `, companyRef);
    return (<Panel title="Allmänt">
      <KeyValue label="Skapat" value={formatDate(toDate(company.timestampId), 'dateTime')}/>
    </Panel>);
};
export default CompanyInfoPanel;
