import React, { useMemo } from 'react';
import { Flexbox } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { useParams } from 'react-router';
import UserInfoPanel from '~/panels/UserInfoPanel';
import UserSidebar from '~/sidebars/UserSidebar';
export const UserDetailsPage = () => {
    const { userId } = useParams();
    const data = useLazyLoadQuery(graphql `
      query UserDetailsPageQuery($userId: ID!) {
        admin_user(id: $userId) {
          ...UserInfoPanel_user
          ...UserSidebar_user
        }
      }
    `, {
        userId,
    });
    const user = useMemo(() => data.admin_user, [data]);
    return (<Flexbox direction="row" gap="large">
      <div style={{ flex: 3 }}>
        <UserInfoPanel userRef={user}/>
      </div>
      <div style={{ flex: 1 }}>
        <UserSidebar userRef={user}/>
      </div>
    </Flexbox>);
};
export default UserDetailsPage;
