import React, { Suspense } from 'react';
import { Outlet, useParams } from 'react-router';
import { BasicPage, Flexbox, H1, Menu, MenuItem, Skeleton } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
export const CompanyLayout = () => {
    const { companyId } = useParams();
    const { company } = useLazyLoadQuery(graphql `
      query CompanyLayoutQuery($companyId: ID!) {
        company(id: $companyId) {
          name
        }
      }
    `, {
        companyId,
    });
    return (<BasicPage width="large">
      <Flexbox direction="row" gap="medium" style={{ paddingTop: '3rem' }}>
        <H1>{company.name}</H1>
      </Flexbox>
      <Flexbox direction="column" gap="xlarge">
        <Menu>
          <MenuItem index path="" text="Överblick"/>
          <MenuItem path="users" text="Användare"/>
          <MenuItem path="assets" text="Enheter"/>
          <MenuItem path="events" text="Händelser"/>
          <MenuItem path="assignment-types" text="Roller"/>
          <MenuItem path="stats" text="Statistik"/>
        </Menu>
        <Suspense fallback={<Skeleton height="40vh" type="table"/>}>
          <Outlet />
        </Suspense>
      </Flexbox>
    </BasicPage>);
};
export default CompanyLayout;
