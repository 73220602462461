import React, { Suspense, useRef, useState } from 'react';
import { Box, Button, Flexbox, IntervalInput, Skeleton } from '@sonika-se/ui-kit/components';
import { useParams } from 'react-router-dom';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { addMonths, startOfMonth } from 'date-fns';
import { MetricsDetails } from '~/components/MetricsDetails';
import StatDetails from '~/components/StatDetails';
export const CompanyStatsPage = () => {
    const { companyId } = useParams();
    const now = new Date();
    const initialFromDateRef = useRef(startOfMonth(now));
    const initialToDateRef = useRef(addMonths(initialFromDateRef.current, 1));
    const [fromDate, setFromDate] = useState(initialFromDateRef.current);
    const [toDate, setToDate] = useState(initialToDateRef.current);
    const data = useLazyLoadQuery(graphql `
      query CompanyStatsPageQuery($companyId: ID!, $fromDate: DateTime, $toDate: DateTime) {
        admin_company(id: $companyId) {
          name
          ...MetricsDetails_query @arguments(fromDate: $fromDate, toDate: $toDate)
          ...StatDetails_query
        }
      }
    `, {
        companyId,
        fromDate: initialFromDateRef.current.toISOString(),
        toDate: initialToDateRef.current.toISOString(),
    });
    const [refreshing, setRefreshing] = useState(false);
    const refreshStatDetailsRef = useRef(null);
    const refreshMetricsDetailsRef = useRef(null);
    return (<Flexbox justify="equal">
      <div>
        <Button onClick={() => {
            var _a;
            console.log('refresh click');
            console.log(refreshStatDetailsRef);
            (_a = refreshStatDetailsRef.current) === null || _a === void 0 ? void 0 : _a.refresh();
        }}>
          Uppdatera
        </Button>
        <Suspense fallback={<Box>
              <Skeleton type="loader" height={100}/>
            </Box>}>
          <StatDetails ref={refreshStatDetailsRef} queryRef={data.admin_company}/>
        </Suspense>
      </div>
      <div>
        <Flexbox>
          <IntervalInput onChange={(interval) => {
            setFromDate(interval.fromDate);
            setToDate(interval.toDate);
        }} value={{
            fromDate: fromDate,
            toDate: toDate,
        }}/>
          <Button onClick={() => {
            var _a;
            (_a = refreshMetricsDetailsRef.current) === null || _a === void 0 ? void 0 : _a.refetch({
                fromDate: fromDate.toISOString(),
                toDate: toDate.toISOString(),
            });
        }}>
            Uppdatera
          </Button>
        </Flexbox>
        <Suspense fallback={<Box>
              <Skeleton type="loader" height={100}/>
            </Box>}>
          <MetricsDetails ref={refreshMetricsDetailsRef} queryRef={data.admin_company} onRefreshComplete={() => {
            console.log('refreshed');
        }}/>
        </Suspense>
      </div>
    </Flexbox>);
};
export default CompanyStatsPage;
