/**
 * @generated SignedSource<<ecfc107170b32f9f155ef2f427ea89da>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "fromDate"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "toDate"
        }
    ],
    "kind": "Fragment",
    "metadata": {
        "refetch": {
            "connection": null,
            "fragmentPathInResult": [
                "node"
            ],
            "operation": require('./MetricsDetailsQuery.graphql'),
            "identifierField": "id"
        }
    },
    "name": "MetricsDetails_query",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "CompanyStats",
            "kind": "LinkedField",
            "name": "stats",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": [
                        {
                            "fields": [
                                {
                                    "kind": "Variable",
                                    "name": "fromDate",
                                    "variableName": "fromDate"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "toDate",
                                    "variableName": "toDate"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "filter"
                        }
                    ],
                    "concreteType": "MetricsByName",
                    "kind": "LinkedField",
                    "name": "metricsByName",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalCount",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        }
    ],
    "type": "Company",
    "abstractKey": null
};
node.hash = "26a6eae2f18c2681e69fdf633c9799df";
export default node;
