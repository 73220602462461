/**
 * @generated SignedSource<<11b96d9e1213caeb88e567b3aeaca232>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "where"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventTablePanel_query",
    "selections": [
        {
            "args": [
                {
                    "kind": "Variable",
                    "name": "where",
                    "variableName": "where"
                }
            ],
            "kind": "FragmentSpread",
            "name": "EventTable_query"
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "76b1bc2a65b6ea383a64f7ff0efb4d9a";
export default node;
