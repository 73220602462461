import React, { Suspense } from 'react';
import { ModalToSheetSkeleton, Sidebar, SidebarMenu, SidebarMenuItem, SidebarSection, } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import { faGhost } from '@fortawesome/pro-regular-svg-icons';
import { ImpersonateUserModal, impersonateUserModalQuery } from '~/modals/ImpersonateUserModal';
export const UserSidebar = (props) => {
    const { userRef } = props;
    const user = useFragment(graphql `
      fragment UserSidebar_user on User {
        id
      }
    `, userRef);
    const [impersonateUserModalQueryRef, loadImpersonateUserModalQuery, disposeImpersonateUserModalQuery] = useQueryLoader(impersonateUserModalQuery);
    return (<>
      <Sidebar>
        <SidebarSection title="Verktyg">
          <SidebarMenu>
            <SidebarMenuItem icon={faGhost} onClick={() => {
            loadImpersonateUserModalQuery({ userId: user.id });
        }}>
              Logga in som användare
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarSection>
      </Sidebar>
      <Suspense fallback={<ModalToSheetSkeleton />}>
        {impersonateUserModalQueryRef && (<ImpersonateUserModal preloadedQuery={impersonateUserModalQueryRef} onClose={() => disposeImpersonateUserModalQuery()}/>)}
      </Suspense>
    </>);
};
export default UserSidebar;
