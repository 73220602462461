/**
 * @generated SignedSource<<89d844fdb96061e3967b0ebe70794779>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "assetFilter"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "companyId"
    }, v2 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v4 = {
        "kind": "Variable",
        "name": "where",
        "variableName": "assetFilter"
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v6 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 50
        },
        {
            "kind": "Literal",
            "name": "order",
            "value": {
                "timestampId": "DESC"
            }
        },
        (v4 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "CompanyAssetsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "admin_company",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "args": [
                        (v4 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "AssetTablePanel_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "CompanyAssetsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "admin_company",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v6 /*: any*/),
                    "concreteType": "AssetConnection",
                    "kind": "LinkedField",
                    "name": "admin_assets",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Asset",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v5 /*: any*/),
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "timestampId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v6 /*: any*/),
                    "filters": [
                        "where"
                    ],
                    "handle": "connection",
                    "key": "AssetTable_query_admin_assets",
                    "kind": "LinkedHandle",
                    "name": "admin_assets"
                }
            ]
        },
        "params": {
            "cacheID": "6313f0f2e56f55b47b7a3abcd4cb8e95",
            "id": null,
            "metadata": {},
            "name": "CompanyAssetsPageQuery",
            "operationKind": "query",
            "text": "query CompanyAssetsPageQuery(\n  $companyId: ID!\n  $assetFilter: AssetFilterInput\n) {\n  admin_company(id: $companyId) {\n    name\n    id\n  }\n  ...AssetTablePanel_query_2Ap002\n}\n\nfragment AssetTablePanel_query_2Ap002 on Query {\n  ...AssetTable_query_2Ap002\n}\n\nfragment AssetTable_query_2Ap002 on Query {\n  admin_assets(first: 50, order: {timestampId: DESC}, where: $assetFilter) {\n    edges {\n      node {\n        id\n        name\n        timestampId\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "b45af00ff41932ab3695c65eccb01792";
export default node;
