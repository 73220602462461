import React from 'react';
import { Route, Routes } from 'react-router';
import CompanyAssetsPage from '~/pages/CompanyAssets';
import CompanyAssignmentTypesPage from '~/pages/CompanyAssignmentTypes';
import CompanyDetailsPage from '~/pages/CompanyDetailsPage';
import CompanyEventsPage from '~/pages/CompanyEventsPage';
import CompanyLayout from '~/layouts/CompanyLayout';
import CompanyIndexPage from '~/pages/CompanyIndexPage';
import CompanyStatsPage from '~/pages/CompanyStatsPage';
import CompanyUsersPage from '~/pages/CompanyUsersPage';
export const CompanyRoutes = () => (<Routes>
    <Route path="" element={<CompanyIndexPage />}/>
    <Route element={<CompanyLayout />} path=":companyId/*">
      <Route path="" element={<CompanyDetailsPage />}/>
      <Route path="assets" element={<CompanyAssetsPage />}/>
      <Route path="assignment-types" element={<CompanyAssignmentTypesPage />}/>
      <Route path="events" element={<CompanyEventsPage />}/>
      <Route path="stats" element={<CompanyStatsPage />}/>
      <Route path="users" element={<CompanyUsersPage />}/>
    </Route>
  </Routes>);
export default CompanyRoutes;
