/**
 * @generated SignedSource<<a7c690d75d123ff191713b55ca58c005>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
        }
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": {
            "refetch": {
                "connection": null,
                "fragmentPathInResult": [],
                "operation": require('./App_currentUserQuery.graphql')
            }
        },
        "name": "App_currentUser",
        "selections": [
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AppRoutes_query"
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "CompanyConnection",
                "kind": "LinkedField",
                "name": "admin_companies",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "impersonatedBy",
                        "plural": false,
                        "selections": (v0 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "me",
                        "plural": false,
                        "selections": (v0 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "permissions",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    };
})();
node.hash = "1dd7c16c067b71ff50da379e6393cdea";
export default node;
