import React, { useEffect, useState } from 'react';
import { useMutation, graphql, usePreloadedQuery } from 'react-relay/hooks';
import { faUnlockAlt, faSync } from '@fortawesome/pro-regular-svg-icons';
import { ModalToSheet, ModalToSheetContent, Text, Flexbox, Button, Icon, ModalToSheetHeader, IconButton, CopyableText, Card, CardContent, Divider, } from '@sonika-se/ui-kit/components';
import { useEnvironment, useToasts } from '@sonika-se/ui-kit/hooks';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
export const impersonateUserModalQuery = graphql `
  query ImpersonateUserModalQuery($userId: ID!) {
    user(id: $userId) {
      id
      firstname
      lastname
    }
  }
`;
export const ImpersonateUserModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const { user } = usePreloadedQuery(impersonateUserModalQuery, preloadedQuery);
    const [initializeImpersonate, initializeImpersonateLoading] = useMutation(graphql `
        mutation ImpersonateUserModal_initializeImpersonateMutation($model: InitializeImpersonateRequestInput!) {
          initializeImpersonate(model: $model) {
            token
          }
        }
      `);
    const { frontUrls } = useEnvironment();
    const [impersonateUrl, setImpersonateUrl] = useState();
    const callInitializeImpersonate = () => {
        setImpersonateUrl(null);
        initializeImpersonate({
            onCompleted: (response) => {
                const { token } = response.initializeImpersonate;
                setImpersonateUrl(`${frontUrls['CORE']}account/impersonate?redirectUri=${frontUrls['CORE']}&token=${token}`);
            },
            onError: (error) => {
                console.log({ error });
            },
            variables: {
                model: {
                    userIdToImpersonate: user.id,
                },
            },
        });
    };
    const { addToast } = useToasts();
    useEffect(() => {
        callInitializeImpersonate();
    }, []);
    return (<ModalToSheet size="small" onClose={() => onClose()}>
      <ModalToSheetHeader onClose={() => onClose()} closeButtonLabel="Stäng">
        Logga in som användare
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <Flexbox direction="column" gap="medium">
          <Card background="gray" shadow="none">
            <CardContent style={{ padding: '4rem', textAlign: 'center' }}>
              <Icon color="#0C2AF3" icon={faUnlockAlt} style={{ fontSize: '4rem' }}/>
            </CardContent>
          </Card>
          <Text>
            Högerklicka på knappen nedan och öppna sidan inkognito/privat flik eller kopiera och klistra in länken i en
            annan webbläsare för att logga in som vald användare.
          </Text>
          <Flexbox direction="column" gap="small">
            <a href={impersonateUrl}>
              <Button display="block" disabled={initializeImpersonateLoading}>
                Logga in som {`${user.firstname} ${user.lastname}`}
              </Button>
            </a>
            <Divider />
            <Flexbox direction="row" gap="xsmall">
              <CopyableText disabled={initializeImpersonateLoading} onCopy={() => {
            addToast('Inloggningslänk kopierad till urklipp', { color: sonikaTheme.colors.system.go });
        }} style={{ flex: 1 }} value={impersonateUrl}/>
              <IconButton appearance="outlined" spin={initializeImpersonateLoading} disabled={initializeImpersonateLoading} icon={faSync} onClick={() => {
            callInitializeImpersonate();
        }}/>
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default ImpersonateUserModal;
