import React from 'react';
import { Placeholder, Sidebar, SidebarMenu, SidebarMenuItem, SidebarSection } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import { serviceIcons, serviceNames } from '@sonika-se/ui-kit';
export const CompanySidebar = (props) => {
    const { companyRef } = props;
    const company = useFragment(graphql `
      fragment CompanySidebar_company on Company {
        activeServices
      }
    `, companyRef);
    return (<>
      <Sidebar>
        <SidebarSection title="Tjänster">
          <SidebarMenu>
            {company.activeServices.length === 0 ? (<Placeholder text="Inga tjänster."/>) : (company.activeServices.map((service) => (<SidebarMenuItem icon={serviceIcons[service]} key={service}>
                  {serviceNames[service]}
                </SidebarMenuItem>)))}
          </SidebarMenu>
        </SidebarSection>
      </Sidebar>
    </>);
};
export default CompanySidebar;
