import React from 'react';
import { format } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { usePreloadedQuery, graphql } from 'react-relay/hooks';
import { Flexbox, ModalToSheet, ModalToSheetHeader, ModalToSheetContent, KeyValue } from '@sonika-se/ui-kit/components';
import CompanyLink from '~/links/CompanyLink';
import UserLink from '~/links/UserLink';
export const auditModalQuery = graphql `
  query AuditModalQuery($id: ID!) {
    node(id: $id) {
      ... on Audit {
        company {
          ...CompanyLink_company
        }
        id
        impersonatingUser {
          ...UserLink_user
        }
        newValues
        objectId
        objectType
        oldValues
        tableName
        timestamp
        user {
          ...UserLink_user
        }
        authenticationDescription
      }
    }
  }
`;
export const AuditModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const { node: audit } = usePreloadedQuery(auditModalQuery, preloadedQuery);
    return (<ModalToSheet onClose={() => onClose()}>
      <ModalToSheetHeader onClose={() => onClose()}>Händelse</ModalToSheetHeader>
      <ModalToSheetContent>
        <Flexbox direction="column" gap="medium">
          <KeyValue label="Datum/tid" value={!!(audit === null || audit === void 0 ? void 0 : audit.timestamp) && format(new Date(audit === null || audit === void 0 ? void 0 : audit.timestamp.toString()), 'yyyy-MM-dd HH:mm', { locale })}/>
          <KeyValue label="ID" value={audit.objectId}/>
          <KeyValue label="Typ" value={audit.objectType}/>
          <KeyValue label="Företag">{audit.company ? <CompanyLink companyRef={audit.company}/> : '-'}</KeyValue>

          <KeyValue label="Användare">{audit.user ? <UserLink userRef={audit.user}/> : '-'}</KeyValue>
          <KeyValue label="Impersonatör">
            {audit.impersonatingUser ? <UserLink userRef={audit.impersonatingUser}/> : '-'}
          </KeyValue>
          <KeyValue label="Authentication description" value={`${audit === null || audit === void 0 ? void 0 : audit.authenticationDescription}`}/>
          <KeyValue label="Tidigare värde" value={JSON.stringify(audit === null || audit === void 0 ? void 0 : audit.oldValues)}/>
          <KeyValue label="Nytt värde" value={JSON.stringify(audit === null || audit === void 0 ? void 0 : audit.newValues)}/>
        </Flexbox>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default AuditModal;
