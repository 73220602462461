import React, { useEffect, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { graphql, useLazyLoadQuery, useRefetchableFragment } from 'react-relay/hooks';
import { AppContextProvider } from '@sonika-se/ui-kit/components';
import { useApi } from '@sonika-se/ui-kit/hooks';
import { mapTextsToIntlFormat } from '@sonika-se/ui-kit/utils/misc';
import AppRoutes from './AppRoutes';
import SystemTexts from './SystemTexts';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';
const App = () => {
    var _a;
    const { refreshAccessToken } = useApi();
    const data = useLazyLoadQuery(graphql `
      query AppQuery {
        ...App_currentUser
        currentUser {
          id
        }
      }
    `, {});
    const [fragment, refetch] = useRefetchableFragment(graphql `
      fragment App_currentUser on Query @refetchable(queryName: "App_currentUserQuery") {
        ...AppRoutes_query
        admin_companies {
          totalCount # Trigger auth check
        }
        currentUser {
          impersonatedBy {
            id
            firstname
            lastname
          }
          me {
            id
            firstname
            lastname
          }
          permissions
        }
      }
    `, data);
    const { currentUser } = fragment;
    useEffect(() => {
        if (!currentUser) {
            refreshAccessToken().then(() => {
                refetch(null, {
                    fetchPolicy: 'network-only',
                    onComplete: (error) => {
                        console.log({ error });
                    },
                });
            });
        }
    }, [currentUser]);
    const [texts, setTexts] = React.useState([]);
    const [breadcrumbs, setBreadcrumbs] = React.useState();
    if (!currentUser) {
        return null;
    }
    return (<>
      <Suspense fallback={<div />}>
        <SystemTexts onChange={(texts) => setTexts(texts)}/>
      </Suspense>
      <AppContextProvider value={{
            breadcrumbs,
            currentUser: (currentUser === null || currentUser === void 0 ? void 0 : currentUser.me) && {
                id: currentUser.me.id,
                name: `${currentUser.me.firstname} ${currentUser.me.lastname}`,
                impersonatedBy: currentUser.impersonatedBy && {
                    id: (_a = currentUser.impersonatedBy) === null || _a === void 0 ? void 0 : _a.id,
                    name: `${currentUser.impersonatedBy.firstname} ${currentUser.impersonatedBy.lastname}`,
                },
                permissions: currentUser.permissions,
            },
            setBreadcrumbs,
        }}>
        <IntlProvider onError={(error) => {
            // console.log('Intl error', error)
        }} locale="sv" messages={mapTextsToIntlFormat(texts)}>
          <BrowserRouter>
            <AppRoutes queryRef={fragment}/>
          </BrowserRouter>
        </IntlProvider>
      </AppContextProvider>
    </>);
};
export default withAITracking(reactPlugin, App);
