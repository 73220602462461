import React from 'react';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { formatISO } from 'date-fns';
import { useIntl } from 'react-intl';
import { Placeholder, DataTable, Button, Flexbox, Icon, Tooltip } from '@sonika-se/ui-kit/components';
import { useDidMountEffect } from '@sonika-se/ui-kit/hooks';
import { formatDate, toDate } from '@sonika-se/ui-kit/utils/time';
import { Link } from 'react-router-dom';
import { faGhost } from '@fortawesome/pro-regular-svg-icons';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import UserLink from '~/links/UserLink';
export const AuditTable = (props) => {
    const { filters, onShowObject, queryRef, dateInterval } = props;
    const { data, hasNext, isLoadingNext, loadNext, refetch } = usePaginationFragment(graphql `
      fragment AuditTable_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        first: { type: "Int", defaultValue: 10 }
        where: { type: "AuditFilterInput" }
      )
      @refetchable(queryName: "AuditTablePaginationQuery") {
        audits(after: $after, first: $first, order: { timestamp: DESC }, where: $where)
          @connection(key: "AuditTable_audits", filters: ["where"]) {
          edges {
            node {
              id
              impersonatingUser {
                ...UserLink_user
                firstname
                lastname
              }
              objectType
              timestamp
              user {
                ...UserLink_user
              }
              company {
                id
                name
              }
            }
          }
        }
      }
    `, queryRef);
    useDidMountEffect(() => {
        var _a;
        const { type, companyId } = filters;
        const where = {};
        if (!!(dateInterval === null || dateInterval === void 0 ? void 0 : dateInterval.fromDate) && !!(dateInterval === null || dateInterval === void 0 ? void 0 : dateInterval.toDate)) {
            where.timestamp = { gte: formatISO(dateInterval === null || dateInterval === void 0 ? void 0 : dateInterval.fromDate) };
            where.timestamp = { lte: formatISO(dateInterval === null || dateInterval === void 0 ? void 0 : dateInterval.toDate) };
        }
        if (!!type) {
            where.objectType = {
                eq: type,
            };
        }
        if (!!companyId) {
            where.companyId = { eq: companyId };
        }
        refetch({
            where: ((_a = Object.keys(where)) === null || _a === void 0 ? void 0 : _a.length) ? where : undefined,
        }, { fetchPolicy: 'network-only' });
    }, [filters.search, filters.type, dateInterval, filters.companyId]);
    const { audits } = data;
    const intl = useIntl();
    if (!audits.edges.length) {
        return <Placeholder text="Det finns inga inlägg i aktivitetsloggen"/>;
    }
    return (<DataTable columns={{
            timestamp: {
                render: (timestamp) => (<span style={{ whiteSpace: 'nowrap' }}>{formatDate(toDate(timestamp), 'dateTime')}</span>),
                text: intl.formatMessage({
                    defaultMessage: 'Datum/tid',
                    id: 'general.misc.audit.fields.timestamp.label',
                }),
            },
            objectType: {
                text: 'Objekttyp',
            },
            company: {
                render: (company) => company && <Link to={`/companies/${company.id}`}>{company.name}</Link>,
                text: 'Företag',
            },
            user: {
                text: 'Användare',
                render: ({ impersonatingUser, user }) => user && impersonatingUser ? (<Flexbox direction="row" gap="xxsmall">
                <UserLink userRef={user}/>
                <Tooltip content={`${impersonatingUser.firstname} ${impersonatingUser.lastname}`}>
                  <UserLink userRef={impersonatingUser}>
                    <Icon color={sonikaTheme.colors.primary} icon={faGhost}/>
                  </UserLink>
                </Tooltip>
              </Flexbox>) : user ? (<UserLink userRef={user}/>) : undefined,
            },
            actions: {
                align: 'right',
                render: (id) => (<Button onClick={() => {
                        onShowObject(id);
                    }} size="small">
              Visa
            </Button>),
            },
        }} loading={isLoadingNext} onPaginate={() => loadNext(50)} pagination={{
            buttonText: intl.formatMessage({ defaultMessage: 'Ladda fler', id: 'general.actions.load_more' }),
            hasNextPage: hasNext,
        }} rows={audits &&
            audits.edges.map(({ node: audit }) => ({
                timestamp: audit.timestamp,
                objectType: audit.objectType,
                company: audit.company
                    ? {
                        id: audit.company.id,
                        name: audit.company.name,
                    }
                    : undefined,
                user: {
                    user: audit.user,
                    impersonatingUser: audit.impersonatingUser,
                },
                actions: audit.id,
            }))}/>);
};
