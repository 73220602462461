import React from 'react';
import { Panel } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import EventTable from '~/tables/EventTable';
export const EventTablePanel = (props) => {
    const { queryRef } = props;
    const data = useFragment(graphql `
      fragment EventTablePanel_query on Query @argumentDefinitions(where: { type: "EventFilterInput" }) {
        ...EventTable_query @arguments(where: $where)
      }
    `, queryRef);
    return (<Panel title="Händelser">
      <EventTable queryRef={data}/>
    </Panel>);
};
export default EventTablePanel;
