/**
 * @generated SignedSource<<b1438b29293c7a562d15ce7ba698f31d>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "companyId"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "fromDate"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "toDate"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v3 = [
        {
            "kind": "Variable",
            "name": "fromDate",
            "variableName": "fromDate"
        },
        {
            "kind": "Variable",
            "name": "toDate",
            "variableName": "toDate"
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CompanyStatsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "admin_company",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "args": (v3 /*: any*/),
                            "kind": "FragmentSpread",
                            "name": "MetricsDetails_query"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "StatDetails_query"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CompanyStatsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "admin_company",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CompanyStats",
                            "kind": "LinkedField",
                            "name": "stats",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "fields": (v3 /*: any*/),
                                            "kind": "ObjectValue",
                                            "name": "filter"
                                        }
                                    ],
                                    "concreteType": "MetricsByName",
                                    "kind": "LinkedField",
                                    "name": "metricsByName",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "totalCount",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UserStats",
                                    "kind": "LinkedField",
                                    "name": "userStats",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UsersPerService",
                                            "kind": "LinkedField",
                                            "name": "usersPerService",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "service",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "numberOfUsers",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "numberOfActiveUsers",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "numberOfActiveInvitedUsers",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "numberOfCompanyAdmins",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssetStats",
                                    "kind": "LinkedField",
                                    "name": "assetStats",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "numberOfActiveAssets",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CompanyTimeStats",
                                    "kind": "LinkedField",
                                    "name": "timeStats",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "numberOfTimeReportingUsers",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CompanyScheduleStats",
                                    "kind": "LinkedField",
                                    "name": "scheduleStats",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "numberOfSchedulableAssets",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "numberOfSchedulableUsers",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "numberOfSchedules",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CompanyEmploymentStats",
                                    "kind": "LinkedField",
                                    "name": "employmentStats",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "numberOfActiveEmployments",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CompanySurveyStats",
                                    "kind": "LinkedField",
                                    "name": "surveyStats",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "numberOfSurveys",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "numberOfActiveSurveys",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "numberOfCollectors",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "numberOfActiveCollectors",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "numberOfResponses",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "numberOfFinishedResponses",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "numberOfActiveResponses",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "numberOfActiveFinishedResponses",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "activeServices",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "35df4f5825468fb9e57d7eda692bde6e",
            "id": null,
            "metadata": {},
            "name": "CompanyStatsPageQuery",
            "operationKind": "query",
            "text": "query CompanyStatsPageQuery(\n  $companyId: ID!\n  $fromDate: DateTime\n  $toDate: DateTime\n) {\n  admin_company(id: $companyId) {\n    name\n    ...MetricsDetails_query_L3dfJ\n    ...StatDetails_query\n    id\n  }\n}\n\nfragment MetricsDetails_query_L3dfJ on Company {\n  stats {\n    metricsByName(filter: {fromDate: $fromDate, toDate: $toDate}) {\n      name\n      totalCount\n    }\n  }\n  id\n}\n\nfragment StatDetails_query on Company {\n  activeServices\n  stats {\n    userStats {\n      usersPerService {\n        service\n        numberOfUsers\n      }\n      numberOfActiveUsers\n      numberOfActiveInvitedUsers\n      numberOfCompanyAdmins\n    }\n    assetStats {\n      numberOfActiveAssets\n    }\n    timeStats {\n      numberOfTimeReportingUsers\n    }\n    scheduleStats {\n      numberOfSchedulableAssets\n      numberOfSchedulableUsers\n      numberOfSchedules\n    }\n    employmentStats {\n      numberOfActiveEmployments\n    }\n    surveyStats {\n      numberOfSurveys\n      numberOfActiveSurveys\n      numberOfCollectors\n      numberOfActiveCollectors\n      numberOfResponses\n      numberOfFinishedResponses\n      numberOfActiveResponses\n      numberOfActiveFinishedResponses\n    }\n  }\n  id\n}\n"
        }
    };
})();
node.hash = "b368fc7d2c2da8f11a6238ac58af940c";
export default node;
