import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Link } from 'react-router-dom';
export const UserLink = (props) => {
    const { children, userRef } = props;
    const user = useFragment(graphql `
      fragment UserLink_user on User {
        id
        firstname
        lastname
      }
    `, userRef);
    return <Link to={`/users/${user.id}`}>{children || `${user.firstname} ${user.lastname}`}</Link>;
};
export default UserLink;
