/**
 * @generated SignedSource<<dcd257f2b698a3573b0ebc559285f1fb>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanyInfoPanel_company",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timestampId",
            "storageKey": null
        }
    ],
    "type": "Company",
    "abstractKey": null
};
node.hash = "9de153192363cacbfbbd1b10436ef2ac";
export default node;
