import React from 'react';
import { Panel } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import AssignmentTypeTable from '~/tables/AssignmentTypeTable';
export const AssignmentTypeTablePanel = (props) => {
    const { queryRef } = props;
    const data = useFragment(graphql `
      fragment AssignmentTypeTablePanel_query on Query
      @argumentDefinitions(where: { type: "AssignmentTypeFilterInput" }) {
        ...AssignmentTypeTable_query @arguments(where: $where)
      }
    `, queryRef);
    return (<Panel title="Roller">
      <AssignmentTypeTable queryRef={data}/>
    </Panel>);
};
export default AssignmentTypeTablePanel;
