import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Link } from 'react-router-dom';
export const CompanyLink = (props) => {
    const { children, companyRef } = props;
    const company = useFragment(graphql `
      fragment CompanyLink_company on Company {
        id
        name
      }
    `, companyRef);
    return <Link to={`/companies/${company.id}`}>{children || company.name}</Link>;
};
export default CompanyLink;
