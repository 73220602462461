import React from 'react';
import { useParams } from 'react-router-dom';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import AssetTablePanel from '~/panels/AssetTablePanel';
export const CompanyAssetsPage = () => {
    const { companyId } = useParams();
    const data = useLazyLoadQuery(graphql `
      query CompanyAssetsPageQuery($companyId: ID!, $assetFilter: AssetFilterInput) {
        admin_company(id: $companyId) {
          name
        }
        ...AssetTablePanel_query @arguments(where: $assetFilter)
      }
    `, {
        companyId,
        assetFilter: { companyId: { eq: companyId } },
    });
    return <AssetTablePanel queryRef={data}/>;
};
export default CompanyAssetsPage;
