import React, { useMemo } from 'react';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { DataTable } from '@sonika-se/ui-kit/components';
import { formatDate, toDate } from '@sonika-se/ui-kit/utils/time';
import UserLink from '~/links/UserLink';
import CompanyLink from '~/links/CompanyLink';
export const UserTable = (props) => {
    const { queryRef } = props;
    const { data, hasNext, loadNext } = usePaginationFragment(graphql `
      fragment UserTable_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        first: { type: "Int", defaultValue: 50 }
        where: { type: "UserFilterInput" }
      )
      @refetchable(queryName: "UserTablePaginationQuery") {
        admin_users(after: $after, first: $first, order: { timestampId: DESC }, where: $where)
          @connection(key: "UserTable_query_admin_users", filters: ["where"]) {
          edges {
            node {
              ...UserLink_user
              company {
                ...CompanyLink_company
              }
              email
              timestampId
            }
          }
        }
      }
    `, queryRef);
    const users = useMemo(() => data.admin_users.edges.map(({ node: user }) => ({
        name: user,
        company: user.company,
        email: user.email,
        timestampId: user.timestampId,
    })), [data]);
    return (<DataTable columns={{
            name: {
                render: (user) => <UserLink userRef={user}/>,
                text: 'Namn',
            },
            company: {
                render: (company) => <CompanyLink companyRef={company}/>,
                text: 'Företag',
            },
            email: {
                text: 'Epost',
            },
            timestampId: {
                render: (timestamp) => (<span style={{ whiteSpace: 'nowrap' }}>{formatDate(toDate(timestamp), 'dateTime')}</span>),
                text: 'Skapad',
                width: 1,
            },
        }} onPaginate={() => loadNext(50)} pagination={{
            buttonText: 'Ladda fler',
            hasNextPage: hasNext,
        }} rows={users}/>);
};
export default UserTable;
