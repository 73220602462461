import React from 'react';
import { Panel } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import UserTable from '~/tables/UserTable';
export const UserTablePanel = (props) => {
    const { queryRef } = props;
    const data = useFragment(graphql `
      fragment UserTablePanel_query on Query @argumentDefinitions(where: { type: "UserFilterInput" }) {
        ...UserTable_query @arguments(where: $where)
      }
    `, queryRef);
    return (<Panel title="Användare">
      <UserTable queryRef={data}/>
    </Panel>);
};
export default UserTablePanel;
