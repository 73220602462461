/**
 * @generated SignedSource<<8b14e26f86496b3bb804c483a007edad>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 50
        },
        {
            "kind": "Literal",
            "name": "order",
            "value": {
                "timestampId": "DESC"
            }
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "CompanyIndexPageQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CompanyTablePanel_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "CompanyIndexPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "concreteType": "CompanyConnection",
                    "kind": "LinkedField",
                    "name": "admin_companies",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CompanyEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Company",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "activeServices",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "administrationEmail",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "timestampId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UserConnection",
                                            "kind": "LinkedField",
                                            "name": "users",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "totalCount",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": "admin_companies(first:50,order:{\"timestampId\":\"DESC\"})"
                },
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "filters": [
                        "where"
                    ],
                    "handle": "connection",
                    "key": "CompanyTable_query_admin_companies",
                    "kind": "LinkedHandle",
                    "name": "admin_companies"
                }
            ]
        },
        "params": {
            "cacheID": "8a9aac307b4e70db8bf354205c088ee6",
            "id": null,
            "metadata": {},
            "name": "CompanyIndexPageQuery",
            "operationKind": "query",
            "text": "query CompanyIndexPageQuery {\n  ...CompanyTablePanel_query\n}\n\nfragment CompanyTablePanel_query on Query {\n  ...CompanyTable_query\n}\n\nfragment CompanyTable_query on Query {\n  admin_companies(first: 50, order: {timestampId: DESC}) {\n    edges {\n      node {\n        activeServices\n        administrationEmail\n        id\n        name\n        timestampId\n        users {\n          totalCount\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "84ed34005875a235b38388a9571e1932";
export default node;
