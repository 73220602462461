import React, { useMemo, useState } from 'react';
import { BasicPage, BasicPageHeader, Card, CardContent, Flexbox, Input, Label, Panel, } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import UserTablePanel from '~/panels/UserTablePanel';
import { useDebounce } from '@sonika-se/ui-kit/hooks';
export const UserIndexPage = () => {
    const [search, setSearch] = useState('');
    const delayedSearch = useDebounce(search, 300);
    const userFilter = useMemo(() => {
        return {
            or: [{ firstname: { contains: delayedSearch } }, { lastname: { contains: delayedSearch } }],
        };
    }, [delayedSearch]);
    const data = useLazyLoadQuery(graphql `
      query UserIndexPageQuery($where: UserFilterInput) {
        ...UserTablePanel_query @arguments(where: $where)
      }
    `, {
        where: userFilter,
    });
    return (<BasicPage width="large">
      <BasicPageHeader title="Användare"/>
      <Panel>
        <Flexbox direction="column" gap="small">
          <Card background="gray" shadow="none">
            <CardContent>
              <Flexbox gap="medium">
                <Label lineHeight="double" text="Namn">
                  <Input inputName="search" placeholder="Sök" onChange={(value) => setSearch(value)}/>
                </Label>
              </Flexbox>
            </CardContent>
          </Card>
        </Flexbox>
      </Panel>
      <UserTablePanel queryRef={data}/>
    </BasicPage>);
};
export default UserIndexPage;
