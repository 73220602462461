import React from 'react';
import { useParams } from 'react-router-dom';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import EventTablePanel from '../panels/EventTablePanel';
export const CompanyEventsPage = () => {
    const { companyId } = useParams();
    const data = useLazyLoadQuery(graphql `
      query CompanyEventsPageQuery($companyId: ID!, $eventFilter: EventFilterInput) {
        admin_company(id: $companyId) {
          name
        }
        ...EventTablePanel_query @arguments(where: $eventFilter)
      }
    `, {
        companyId,
        eventFilter: { companyId: { eq: companyId } },
    });
    return <EventTablePanel queryRef={data}/>;
};
export default CompanyEventsPage;
