import React, { Suspense, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { matchPath, useNavigate, useRoutes } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { App, Skeleton } from '@sonika-se/ui-kit/components';
import { useEnvironment } from '@sonika-se/ui-kit/hooks';
import { serviceIcons, serviceNames } from '@sonika-se/ui-kit';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons';
import ErrorBoundary from './shared/components/ErrorBoundary';
import HomePage from './pages/HomePage';
import { AuditIndexPage } from './pages/AuditIndexPage';
import CompanyRoutes from './routes/CompanyRoutes';
import UserRoutes from './routes/UserRoutes';
export const AppRoutes = (props) => {
    var _a;
    const { queryRef } = props;
    const data = useFragment(graphql `
      fragment AppRoutes_query on Query {
        currentUser {
          me {
            avatar {
              url(queryTransform: "width=68&height=68&mode=crop&scale=both")
            }
            color
            firstname
            lastname
          }
          permissions
          services
        }
      }
    `, queryRef);
    const { currentUser } = data;
    const intl = useIntl();
    const navigate = useNavigate();
    const { frontUrls } = useEnvironment();
    const menuItems = useMemo(() => [
        {
            text: 'Företag',
            url: '/companies',
        },
        {
            text: 'Användare',
            url: '/users',
        },
        {
            text: 'Aktivitetetslogg',
            url: '/audit',
        },
    ], []);
    const routes = useRoutes([
        {
            path: '',
            element: <HomePage />,
        },
        {
            path: 'audit',
            element: <AuditIndexPage />,
        },
        {
            path: 'companies/*',
            element: <CompanyRoutes />,
        },
        {
            path: 'users/*',
            element: <UserRoutes />,
        },
    ]);
    return (<App apps={currentUser
            ? ['CORE', ...data.currentUser.services].map((service) => ({
                icon: serviceIcons[service],
                name: intl.formatMessage({
                    defaultMessage: serviceNames[service],
                    id: `general.services.${service.toLowerCase()}.name`,
                }),
                url: frontUrls[service],
            }))
            : null} icon={faGlobe} menu={{
            items: menuItems.map((item) => (Object.assign(Object.assign({}, item), { selected: matchPath({
                    path: item.url,
                    end: item.url === '/',
                }, location.pathname) !== null }))),
            onNavigate: (url) => {
                navigate(url);
            },
        }} name="Admin" user={currentUser
            ? {
                color: currentUser.me.color,
                firstname: currentUser.me.firstname,
                lastname: currentUser.me.lastname,
                imageUrl: (_a = currentUser.me.avatar) === null || _a === void 0 ? void 0 : _a.url,
            }
            : undefined}>
      <ErrorBoundary>
        <Suspense fallback={<Skeleton height="100%" type="page"/>}>{routes}</Suspense>
      </ErrorBoundary>
    </App>);
};
export default AppRoutes;
