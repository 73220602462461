import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { Flexbox, Input, Label, Panel, Card, CardContent, } from '@sonika-se/ui-kit/components';
import { useDebounce } from '@sonika-se/ui-kit/hooks';
import UserTablePanel from '~/panels/UserTablePanel';
export const CompanyUsersPage = () => {
    const { companyId } = useParams();
    const [search, setSearch] = useState('');
    const delayedSearch = useDebounce(search, 300);
    const userFilter = useMemo(() => {
        return {
            and: [
                { companyId: { eq: companyId } },
                {
                    or: [{ firstname: { contains: delayedSearch } }, { lastname: { contains: delayedSearch } }],
                },
            ],
        };
    }, [companyId, delayedSearch]);
    const data = useLazyLoadQuery(graphql `
      query CompanyUsersPageQuery($companyId: ID!, $userFilter: UserFilterInput) {
        admin_company(id: $companyId) {
          name
        }
        ...UserTablePanel_query @arguments(where: $userFilter)
      }
    `, {
        companyId,
        userFilter: userFilter,
    });
    return (<>
      <Panel>
        <Flexbox direction="column" gap="small">
          <Card background="gray" shadow="none">
            <CardContent>
              <Flexbox gap="medium">
                <Label lineHeight="double" text="Namn">
                  <Input inputName="search" placeholder="Sök" onChange={(value) => setSearch(value)}/>
                </Label>
              </Flexbox>
            </CardContent>
          </Card>
        </Flexbox>
      </Panel>
      <UserTablePanel queryRef={data}/>
    </>);
};
export default CompanyUsersPage;
