/**
 * @generated SignedSource<<10bc6dc7a50b3a9eaafc3cff3de73c1c>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "after"
        },
        {
            "defaultValue": 50,
            "kind": "LocalArgument",
            "name": "first"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "where"
        }
    ], v1 = {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
    }, v2 = {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
    }, v3 = {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
    }, v4 = [
        (v1 /*: any*/),
        (v2 /*: any*/),
        {
            "kind": "Literal",
            "name": "order",
            "value": {
                "timestampId": "DESC"
            }
        },
        (v3 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssignmentTypeTablePaginationQuery",
            "selections": [
                {
                    "args": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "AssignmentTypeTable_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssignmentTypeTablePaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "AssignmentTypeConnection",
                    "kind": "LinkedField",
                    "name": "admin_assignmentTypes",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentTypeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentType",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "timestampId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "filters": [
                        "where"
                    ],
                    "handle": "connection",
                    "key": "AssignmentTypeTable_query_admin_assignmentTypes",
                    "kind": "LinkedHandle",
                    "name": "admin_assignmentTypes"
                }
            ]
        },
        "params": {
            "cacheID": "d2d759e9ed5d03bc56122ac69c0e0c6e",
            "id": null,
            "metadata": {},
            "name": "AssignmentTypeTablePaginationQuery",
            "operationKind": "query",
            "text": "query AssignmentTypeTablePaginationQuery(\n  $after: String\n  $first: Int = 50\n  $where: AssignmentTypeFilterInput\n) {\n  ...AssignmentTypeTable_query_3sueo6\n}\n\nfragment AssignmentTypeTable_query_3sueo6 on Query {\n  admin_assignmentTypes(after: $after, first: $first, order: {timestampId: DESC}, where: $where) {\n    edges {\n      node {\n        id\n        timestampId\n        title\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "b206ec51297195c25cae00763a0c5994";
export default node;
