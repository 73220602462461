/**
 * @generated SignedSource<<ab8055aab0708570a8928d6e750962f7>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "App_currentUserQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "App_currentUser"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "App_currentUserQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CurrentUser",
                    "kind": "LinkedField",
                    "name": "currentUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "me",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Avatar",
                                    "kind": "LinkedField",
                                    "name": "avatar",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": [
                                                {
                                                    "kind": "Literal",
                                                    "name": "queryTransform",
                                                    "value": "width=68&height=68&mode=crop&scale=both"
                                                }
                                            ],
                                            "kind": "ScalarField",
                                            "name": "url",
                                            "storageKey": "url(queryTransform:\"width=68&height=68&mode=crop&scale=both\")"
                                        },
                                        (v0 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "color",
                                    "storageKey": null
                                },
                                (v1 /*: any*/),
                                (v2 /*: any*/),
                                (v0 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "permissions",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "services",
                            "storageKey": null
                        },
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "impersonatedBy",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                (v1 /*: any*/),
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyConnection",
                    "kind": "LinkedField",
                    "name": "admin_companies",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalCount",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "1840c63914d42c98193c23a5cc1386de",
            "id": null,
            "metadata": {},
            "name": "App_currentUserQuery",
            "operationKind": "query",
            "text": "query App_currentUserQuery {\n  ...App_currentUser\n}\n\nfragment AppRoutes_query on Query {\n  currentUser {\n    me {\n      avatar {\n        url(queryTransform: \"width=68&height=68&mode=crop&scale=both\")\n        id\n      }\n      color\n      firstname\n      lastname\n      id\n    }\n    permissions\n    services\n    id\n  }\n}\n\nfragment App_currentUser on Query {\n  ...AppRoutes_query\n  admin_companies {\n    totalCount\n  }\n  currentUser {\n    impersonatedBy {\n      id\n      firstname\n      lastname\n    }\n    me {\n      id\n      firstname\n      lastname\n    }\n    permissions\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "1dd7c16c067b71ff50da379e6393cdea";
export default node;
