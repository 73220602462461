/**
 * @generated SignedSource<<07fdf4e52d99aa5fa8400a8b7267a566>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = [
        (v0 /*: any*/),
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        }
    ], v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "first",
                "value": 100
            },
            {
                "kind": "Literal",
                "name": "order",
                "value": {
                    "name": "ASC"
                }
            }
        ],
        "concreteType": "CompanyConnection",
        "kind": "LinkedField",
        "name": "admin_companies",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": (v1 /*: any*/),
                "storageKey": null
            }
        ],
        "storageKey": "admin_companies(first:100,order:{\"name\":\"ASC\"})"
    }, v3 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 10
        },
        {
            "kind": "Literal",
            "name": "order",
            "value": {
                "timestamp": "DESC"
            }
        }
    ], v4 = [
        (v0 /*: any*/),
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "AuditIndexPageQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AuditTable_query"
                },
                (v2 /*: any*/)
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "AuditIndexPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "AuditConnection",
                    "kind": "LinkedField",
                    "name": "audits",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuditEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Audit",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v0 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "impersonatingUser",
                                            "plural": false,
                                            "selections": (v4 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "objectType",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "timestamp",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "user",
                                            "plural": false,
                                            "selections": (v4 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Company",
                                            "kind": "LinkedField",
                                            "name": "company",
                                            "plural": false,
                                            "selections": (v1 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": "audits(first:10,order:{\"timestamp\":\"DESC\"})"
                },
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "filters": [
                        "where"
                    ],
                    "handle": "connection",
                    "key": "AuditTable_audits",
                    "kind": "LinkedHandle",
                    "name": "audits"
                },
                (v2 /*: any*/)
            ]
        },
        "params": {
            "cacheID": "e2ce37c7940ee58a55b4f6b8cb0a6024",
            "id": null,
            "metadata": {},
            "name": "AuditIndexPageQuery",
            "operationKind": "query",
            "text": "query AuditIndexPageQuery {\n  ...AuditTable_query\n  admin_companies(first: 100, order: {name: ASC}) {\n    nodes {\n      id\n      name\n    }\n  }\n}\n\nfragment AuditTable_query on Query {\n  audits(first: 10, order: {timestamp: DESC}) {\n    edges {\n      node {\n        id\n        impersonatingUser {\n          ...UserLink_user\n          firstname\n          lastname\n          id\n        }\n        objectType\n        timestamp\n        user {\n          ...UserLink_user\n          id\n        }\n        company {\n          id\n          name\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment UserLink_user on User {\n  id\n  firstname\n  lastname\n}\n"
        }
    };
})();
node.hash = "c12b812bf203495d0e5933949e75f6af";
export default node;
