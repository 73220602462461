import React from 'react';
import { Panel } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import AssetTable from '~/tables/AssetTable';
export const AssetTablePanel = (props) => {
    const { queryRef } = props;
    const data = useFragment(graphql `
      fragment AssetTablePanel_query on Query @argumentDefinitions(where: { type: "AssetFilterInput" }) {
        ...AssetTable_query @arguments(where: $where)
      }
    `, queryRef);
    return (<Panel title="Enheter">
      <AssetTable queryRef={data}/>
    </Panel>);
};
export default AssetTablePanel;
