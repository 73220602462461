/**
 * @generated SignedSource<<5ce67a4e5ffe66b5675f7d372bf1d3d4>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "fromDate"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "id"
    }, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "toDate"
    }, v3 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        }
    ], v4 = [
        {
            "kind": "Variable",
            "name": "fromDate",
            "variableName": "fromDate"
        },
        {
            "kind": "Variable",
            "name": "toDate",
            "variableName": "toDate"
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "MetricsDetailsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "args": (v4 /*: any*/),
                            "kind": "FragmentSpread",
                            "name": "MetricsDetails_query"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v2 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Operation",
            "name": "MetricsDetailsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CompanyStats",
                                    "kind": "LinkedField",
                                    "name": "stats",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": [
                                                {
                                                    "fields": (v4 /*: any*/),
                                                    "kind": "ObjectValue",
                                                    "name": "filter"
                                                }
                                            ],
                                            "concreteType": "MetricsByName",
                                            "kind": "LinkedField",
                                            "name": "metricsByName",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "name",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "totalCount",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "type": "Company",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "2d683172909a65d4af2e11008f9d3f73",
            "id": null,
            "metadata": {},
            "name": "MetricsDetailsQuery",
            "operationKind": "query",
            "text": "query MetricsDetailsQuery(\n  $fromDate: DateTime\n  $toDate: DateTime\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...MetricsDetails_query_L3dfJ\n    id\n  }\n}\n\nfragment MetricsDetails_query_L3dfJ on Company {\n  stats {\n    metricsByName(filter: {fromDate: $fromDate, toDate: $toDate}) {\n      name\n      totalCount\n    }\n  }\n  id\n}\n"
        }
    };
})();
node.hash = "26a6eae2f18c2681e69fdf633c9799df";
export default node;
