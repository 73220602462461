/**
 * @generated SignedSource<<af19e5b6a6334a969adccfe45b0735bf>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanySidebar_company",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activeServices",
            "storageKey": null
        }
    ],
    "type": "Company",
    "abstractKey": null
};
node.hash = "900fdf822d59d7b02fa1a4a50ae68264";
export default node;
