/**
 * @generated SignedSource<<0cbd27c45778a114367409cc2ad09aa2>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "locale"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "locale",
            "variableName": "locale"
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SystemTexts_textsQuery",
            "selections": [
                {
                    "args": (v1 /*: any*/),
                    "kind": "FragmentSpread",
                    "name": "SystemTexts_texts"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SystemTexts_textsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "TextConnection",
                    "kind": "LinkedField",
                    "name": "texts",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TextEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Text",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "key",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "value",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "locale",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "scope",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "7cd67bac2f050ef18d155efdf5bca8ff",
            "id": null,
            "metadata": {},
            "name": "SystemTexts_textsQuery",
            "operationKind": "query",
            "text": "query SystemTexts_textsQuery(\n  $locale: Locale!\n) {\n  ...SystemTexts_texts_4mYyp4\n}\n\nfragment SystemTexts_texts_4mYyp4 on Query {\n  texts(locale: $locale) {\n    edges {\n      node {\n        key\n        value\n        locale\n        scope\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "fc6e30d7ca92a60885e2780090b1b058";
export default node;
