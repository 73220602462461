import React from 'react';
import { Route, Routes } from 'react-router';
import UserDetailsPage from '~/pages/UserDetailsPage';
import UserLayout from '~/layouts/UserLayout';
import UserIndexPage from '~/pages/UserIndexPage';
export const UserRoutes = () => (<Routes>
    <Route path="" element={<UserIndexPage />}/>
    <Route element={<UserLayout />} path=":userId/*">
      <Route path="" element={<UserDetailsPage />}/>
    </Route>
  </Routes>);
export default UserRoutes;
