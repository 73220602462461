import React, { useMemo } from 'react';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { DataTable } from '@sonika-se/ui-kit/components';
import { formatDate, toDate } from '@sonika-se/ui-kit/utils/time';
export const AssetTable = (props) => {
    const { queryRef } = props;
    const { data, hasNext, loadNext } = usePaginationFragment(graphql `
      fragment AssetTable_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        first: { type: "Int", defaultValue: 50 }
        where: { type: "AssetFilterInput" }
      )
      @refetchable(queryName: "AssetTablePaginationQuery") {
        admin_assets(after: $after, first: $first, order: { timestampId: DESC }, where: $where)
          @connection(key: "AssetTable_query_admin_assets", filters: ["where"]) {
          edges {
            node {
              id
              name
              timestampId
            }
          }
        }
      }
    `, queryRef);
    const assets = useMemo(() => data.admin_assets.edges.map(({ node: { id, name, timestampId } }) => ({
        name,
        timestampId,
    })), [data]);
    return (<DataTable columns={{
            name: {
                text: 'Namn',
            },
            timestampId: {
                render: (timestamp) => formatDate(toDate(timestamp), 'dateTime'),
                text: 'Skapad',
            },
        }} onPaginate={() => loadNext(50)} pagination={{
            buttonText: 'Ladda fler',
            hasNextPage: hasNext,
        }} rows={assets}/>);
};
export default AssetTable;
