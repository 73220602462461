/**
 * @generated SignedSource<<fe5c839ec222ba0927c5bf122b173256>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "where"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignmentTypeTablePanel_query",
    "selections": [
        {
            "args": [
                {
                    "kind": "Variable",
                    "name": "where",
                    "variableName": "where"
                }
            ],
            "kind": "FragmentSpread",
            "name": "AssignmentTypeTable_query"
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "24a6f6df70bb39c02be0c580e547b86e";
export default node;
