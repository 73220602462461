import React, { useImperativeHandle, useMemo } from 'react';
import { graphql, useRefetchableFragment } from 'react-relay/hooks';
export const MetricsDetails = React.forwardRef((props, forwardedRef) => {
    const { queryRef, onRefreshComplete } = props;
    console.log('render stats');
    const [data, refetch] = useRefetchableFragment(graphql `
      fragment MetricsDetails_query on Company
      @argumentDefinitions(fromDate: { type: "DateTime" }, toDate: { type: "DateTime" })
      @refetchable(queryName: "MetricsDetailsQuery") {
        stats {
          metricsByName(filter: { fromDate: $fromDate, toDate: $toDate }) {
            name
            totalCount
          }
        }
      }
    `, queryRef);
    useImperativeHandle(forwardedRef, () => ({
        refetch: (variables) => {
            console.log('refetch');
            refetch(variables, {
                fetchPolicy: 'network-only',
                onComplete: () => onRefreshComplete === null || onRefreshComplete === void 0 ? void 0 : onRefreshComplete(),
            });
        },
    }), [onRefreshComplete, refetch]);
    const jsonData = useMemo(() => {
        console.log('stringify');
        return JSON.stringify(data, null, 2);
    }, [data]);
    return (<div>
      <pre>{jsonData}</pre>
    </div>);
});
export default MetricsDetails;
