import React from 'react';
import { BasicPage, BasicPageHeader } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import CompanyTablePanel from '~/panels/CompanyTablePanel';
export const CompanyIndexPage = () => {
    const data = useLazyLoadQuery(graphql `
      query CompanyIndexPageQuery {
        ...CompanyTablePanel_query
      }
    `, {});
    return (<BasicPage width="large">
      <BasicPageHeader title="Företag"/>
      <CompanyTablePanel queryRef={data}/>
    </BasicPage>);
};
export default CompanyIndexPage;
