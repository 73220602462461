/**
 * @generated SignedSource<<5c6053a3dd107cc33a40434f4ea37604>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "where"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserTablePanel_query",
    "selections": [
        {
            "args": [
                {
                    "kind": "Variable",
                    "name": "where",
                    "variableName": "where"
                }
            ],
            "kind": "FragmentSpread",
            "name": "UserTable_query"
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "18fe34830bc5be5defa02a711fd4366b";
export default node;
