import React, { Suspense } from 'react';
import { Outlet, useParams } from 'react-router';
import { BasicPage, Flexbox, H1, Menu, MenuItem, Skeleton } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
export const UserLayout = () => {
    const { userId } = useParams();
    const { user } = useLazyLoadQuery(graphql `
      query UserLayoutQuery($userId: ID!) {
        user(id: $userId) {
          firstname
          lastname
        }
      }
    `, {
        userId,
    });
    return (<BasicPage width="large">
      <Flexbox direction="row" gap="medium" style={{ paddingTop: '3rem' }}>
        <H1>{`${user.firstname} ${user.lastname}`}</H1>
      </Flexbox>
      <Flexbox direction="column" gap="xlarge">
        <Menu>
          <MenuItem index path="" text="Överblick"/>
        </Menu>
        <Suspense fallback={<Skeleton height="40vh" type="table"/>}>
          <Outlet />
        </Suspense>
      </Flexbox>
    </BasicPage>);
};
export default UserLayout;
